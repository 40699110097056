
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@font-face {font-family: 'Helvetica';src: url('./assets/Helvetica-Bold.ttf');}

input {
    border-width: 1px
}

$color: #333;
$black: #000000;
$lightBlack: #1B1B1E;
$hoverGrey: #2D2D32;
$darkGrey: #404046;
$grey: #82838B;
$lightGrey: #ddd;
$veryLightGrey:#eee;
$veryveryLightGrey:#f0f0f0;
$white: #FBFFFE;
$orange: #F38B00;
$green: #009187;
$red: #BE0000;
$bblack:#494949;
$bwhite: #fff;
$neon: #39ff14;
$lime: #32cd32;
$blue: #3792cb;
$grassgreen:#63AB62;
$blue: #3792cb;

$bred:#ed3330;
$borange:#f6b93b;
// $borangelight:#f9d384;
$bblue: #60a3bc;
$bgreen: #20bf6b;
* {
    font-size: 16px;
    color: $color;
    font-family: Open Sans;
    line-height: 30px;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
 }

button, a {
    cursor: pointer !important
}

button:disabled {
    cursor: not-allowed !important
}

.title {
    font-size: 18px;
    font-weight: 600
}

hr {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

.pr {
    position: relative
}

.pa {
    position: absolute
}

.pf {
    position: fixed
}

#root {
    height: 100%
}

.wrapper {
    height: 100%
}

.app {
    background: $veryLightGrey;
    height: 100%;
    text-align: center;
    > svg.fs {
        height: 1000px;
        inset: 0 40px 100px 0;
        margin: auto;
        opacity: 0.5
    }
    > svg.mb {
        width: 80%;
        inset: 0 0 440px 0px;
        margin: auto;
        opacity: 0.5
    }    
    > span.fs {
        width: 1000px;
        height: 100px;
        display: block;
        font-size: 60px;
        text-align: center;
        inset: 0 0 600px 0;
        margin: auto;
        opacity: 1;
        font-weight: 600
    }
    > span.mb {
        width: 100%;
        height: 50px;
        display: block;
        font-size: 30px;
        text-align: center;
        inset: 0 0 250px 0;
        margin: auto;
        opacity: 1;
        font-weight: 600
    }
}

@media only screen and (max-width: 1023px) {
    .app {
        > svg.fs, > span.fs {
            display: none
        }
    }
}

@media only screen and (min-width: 1024px) {
    .app {
        > svg.mb, > span.mb {
            display: none
        }
    }
}

.App {
    border-radius: 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 10px;
    margin: auto;
    padding: 10px 20px;
    background: $white;
    width: 320px;
    height: 160px;
    border: 1px solid $color;
    &.App2 {
        height: 350px;
    }
    svg {
        width: 200px;
        margin-bottom: 20px
    }
}

.amplituda, .amplituda2 {
    left: 0;
    right: 390px;
    bottom: 0;
    margin: auto;
    color: $darkGrey;
    text-transform: uppercase;
    font-size: 11px;
    height: 10px;
    z-index: 9999;
}

.amplituda {
    top: -225px
}

.amplituda2 {
    top: -345px
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.ml {
    margin-left: 10px;
}

.ml7 {
    margin-left: 7px;
}

.mr7 {
    margin-right: 7px;
}

.ml20 {
    margin-left: 20px;
}

.ml15 {
    margin-left: 15px;
}

.mt {
    margin-top: 10px;
}

.mt8 {
    margin-top: 8px;
}

.mt3 {
    margin-top: 3px;
}

.mt2 {
    margin-top: 2px;
}

.mt-2 {
    margin-top: -2px;
}
.mt4 {
    margin-top: 4px;
}

.w9 {
    width: calc(11.1111111% - 15px) !important
}

.mt20 {
    margin-top: 20px;
}

.mt- {
    margin-top: -10px;
}

.mt5 {
    margin-top: 5px;
}

.mr {
    margin-right: 10px;
}

.mr2 {
    margin-right: 2px;
}

.mr5 {
    margin-right: 5px;
}

.mb {
    margin-bottom: 10px;
}

.ml3 {
    margin-left: 3px
}

.ml4 {
    margin-left: 4px
}

.input {
    margin: 4px 0px 4px 10px;
    height: 26px;
    width: 225px;
    &.input1 {
        width: 160px;
    }
    &.input2 {
        width: 120px;
    }
    &.input3 {
        width: 130px;
    }

    &.input-login {
        width: 200px
    }

    &.input-narrow {
        width: 100px
    }
    &.input-very-narrow {
        width: 60px
    }
    &.input-table {
        width: 100%;
        margin: 0px;
        height: 26px;
    }
    &.input-hm {
        width: 35px;
        margin-top: 2px;
        text-align: center
    }
    &.input-hm-narrow {
        width: 28px;
        margin-top: 2px;
        text-align: center
    }    
    &.input-advance {
        margin-top: 2px;
        width: 85px
    }
    &.input-advance0 {
        margin-top: 2px;
        width: 130px;
        &.op0 {
            opacity: 0
        }
    }
    &.input-file {
        z-index: 9999;
        margin: auto;
        inset: 0;        
        opacity: 0;
        background: transparent !important;
        padding: 0 !important;
        margin: 0 !important;
        cursor: pointer;
    }
    &.input-expense-name {
        margin-top: 2px;
        width: 135px
    }
    &.input-expense-value {
        margin-top: 2px;
        width: 75px
    }
    &.input-checkbox {
        margin-top: 2px;
        font-size: 12px;
        width: 15px
    }
    &.input-pass {
        width: 150px
    }

    &.pli {
        padding-left: 6px
    }
}
.input-file-wrapper {
    border: 1px solid $color;
    float: left;
    width: 225px;
    margin-left: 10px;
    height: 40px;
    background: transparent;
    z-index: 0;
}

.input-file-message {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    color: $grey;
    z-index: 1
}

textarea.input {
    height: 120px;
    line-height: 20px;
    resize: none
}

.array-limits {
    top: 1px;
    left: -320px
}

.check-success {
    background: #dfd !important
}

.check-failed {
    background: #fdd !important
}

.my-occupancy {
    background: #dfd !important
}

.not-my-occupancy {
    background: #fdd !important
}

.check-nothing {
    background: #ddf !important
}

.array-number {
    line-height: 10px;
    font-size: 10px;
    width: 60px;
}

.login {
    font-size: 20px;
}

.beforeinput {
    width: 61px;
    line-height: 32px;
}

.beforeinputwide2 {
    width: 420px;
    line-height: 32px;
}

.before-input {
    width: 85px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
}

.before-input-whole {
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 40px
}

.before-input-wide {
    width: 225px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
}

.beforeinputwide {
    width: 275px
}

.beforeinputnarrow {
    width: 40px;
    font-size: 14px
}

.arrow {
    font-size: 20px;
    font-weight: 700;
}

.tal {
    text-align: left;    
}

.tac {
    text-align: center;    
}

.tar {
    text-align: right;    
}

.dbl {
    display: block
}

.dibl {
    display: inline-block
}

.fw {
    width: 65px;
}

.bold {
    font-weight: 700
}

.semi-bold {
    font-weight: 600
}

.checkbox {
    margin-top: 8px
}

.button {
    color: $bblack !important;
    text-decoration: none;
    background: $bwhite;
    padding: 0px 10px;
    border: 2px solid $bblack !important;
    display: inline-block;
    transition: all 0.4s ease 0s;
    &.button-wide {
        padding: 0px 9px;
    }
}

button:disabled {
    background: $veryLightGrey !important;
}

.button-thin {
    padding: 0px 5px;
    line-height: 22px;
    border: 1px solid $bblack !important;
}

.button-mid {
    padding: 2px 8px;
    line-height: 22px;
    border: 1px solid $bblack !important;
}

.user-manual {
    right: 140px;
    top: 8px
}

.user-instructions {
    right: 250px;
    top: 8px
}

.user-instructions2 {
    right: 290px;
    top: 8px
}

.button:hover {
    color: $bwhite !important;
    background: $bgreen;
    border-color: $bgreen !important;
    transition: all 0.4s ease 0s;
}

.button-red {
    color: $bred !important;
    border: 2px solid $bred !important;
    &.button-thin {
        border: 1px solid $bred !important;
    }
    &:hover, &.active {
        color: $bwhite !important;
        background: $bred;
        border-color: $bred !important;
        transition: all 0.4s ease 0s;
    }
    &:hover {
        background: darken($bred, 20%);
        border-color: darken($bred, 20%) !important;
    }

    &.button-nohover {
        color: $bred !important;
        background: $bwhite;
        cursor: default !important
    }

    &:disabled, &.inactive {
        color: $color !important;
        background: $bwhite !important;
        border: 1px solid $bblack !important;
        &:hover {
            color: $color !important;
            background: $bwhite !important;
            border: 1px solid $bblack !important;
        }
    }
    &.button-transparent {
        border: 0px !important;
        padding: 0px;
        &:hover {
            background: $red !important;
        }
    }

}

.cursor-default {
    a {
        cursor: default !important
    }
}

.cursordefault {
    cursor: default !important
}

.button-green {
    color: $bgreen !important;
    border: 2px solid $bgreen !important;
    &.button-thin {
        border: 1px solid $bgreen !important;
    }
    &:hover, &.active {
        color: $bwhite !important;
        background: $bgreen;
        border-color: $bgreen !important;
        transition: all 0.4s ease 0s;
    }
    &:hover {
        background: darken($bgreen, 20%);
        border-color: darken($bgreen, 20%) !important;
    }
    &.button-nohover {
        color: $bgreen !important;
        background: $bwhite;
        cursor: default !important
    }

    &:disabled, &.inactive {
        color: $grey !important;
        background: $bwhite !important;
        border: 1px solid $bblack !important;
        &:hover {
            color: $grey !important;
            background: $bwhite !important;
            border: 1px solid $bblack !important;
        }
    }
}

.button-blue {
    color: $bblue !important;
    border: 2px solid $bblue !important;
    background: $bwhite;
    &.button-thin {
        border: 1px solid $bblue !important;
    }    
}

.button-blue-active, .button-blue:hover:not(.disabled) {
    color: $bwhite !important;
    background: $bblue;
    border-color: $bblue !important;
    transition: all 0.4s ease 0s;
}

.button-blue:hover:not(.disabled) {
    background: darken($bblue, 20%);
    border-color: darken($bblue, 20%) !important;
}

.button-blue.disabled:hover {
    cursor: default !important;
    background: $bwhite;
    border-color: $bblue !important;
    color: $bblue !important;
    &.button-blue-active {
        background: $bblue;
        color: $bwhite !important;    
    }
}

.button-blue {
    &:disabled {
        color: $color !important;
        background: $bwhite !important;
        border: 1px solid $bblack !important;
        &:hover {
            color: $color !important;
            background: $bwhite !important;
            border: 1px solid $bblack !important;
        }
    }
}

.button-orange {
    &:hover, &.button-orange-active {
        color: $bwhite !important;
        background: $borange;
        border-color: $borange !important;
        transition: all 0.4s ease 0s;
    }

    &:hover {
        background: darken($borange, 20%);
        border-color: darken($borange, 20%) !important;
    }

    &:disabled {
        color: $color !important;
        background: $bwhite !important;
        border: 1px solid $bblack !important;
        &:hover {
            color: $color !important;
            background: $bwhite !important;
            border: 1px solid $bblack !important;
        }
    }
}

.red {
    color: $red
}

.bred {
    background: $red
}

.header {
    height: 36px;
    padding: 0px 10px;
    color: $white;
    background: $black;
    &.header-regular {
        background: #228B22
    }
    &.header-admin {
        background: #2471A3
    }
    .userdata {
        text-align: right;
        overflow: hidden;
        width: 320px;
        margin-top: 2px;
        color: $white;
        font-size: 20px;
        span {
            color: $white;
            font-size: 10px;
        }
        a {
            font-weight: 700;
            margin: 5px 0px 0px 0px;
            padding: 2px 4px 1px;
        }
        
        .logout {
            font-size: 12px;
            margin-right: 10px
        }
    }
    a {
        color: $white;
        line-height: 24px;
        margin: 5px 6px 0px;
        &:first-child {margin-left: 0px};
        padding: 1px 10px 0px;
        border: 1px solid transparent;
        &:hover, &.active {
            border: 1px solid $white
        }
    }

    .Nav {
        margin-left: 10px;
        .information {
            position: absolute;
            top: 1px;
            left: 841px;
            border-radius: 50%;
            background: $bred;
            width: 17px;
            height: 17px;
            line-height: 14px;
            padding: 2px 0px;
            text-align: center;
            color: $bwhite;
            font-size: 12px;
            font-weight: 800;
        }
    }

    img.logo {
        height: 26px;
        margin: 5px 5px 0px 10px
    }
    @media only screen and (max-width: 1023px) {
        img.logo {
            position: absolute;
            left: 0px;
            right: 0px
        }
    }
}
.content {
    margin: 10px 20px;
}

@media only screen and (max-width: 359px) {
    .content {
        margin: 10px 0px
    }
    .header {
        padding: 0px 0px;
        img.logo {
            height: 36px;
            margin: 0px 0px 0px 0px
        }
        .userdata {
            .logout {
                margin-right: 0px
            }
        }
    }
}

.h100 {height: 100%}

.companyDetails {
    font-size: 15px;
    line-height: 20px;
}

.ReactTable .rt-thead .rt-th {font-weight: 700}
.ReactTable .rt-resizer {
    display: none !important
}

.rt-smallrow {
    padding: 0px 0px !important;
    line-height: 24px
}

.rt-smallrow div, .rt-middlerow div, .rt-normalrow div {
    padding: 0px 5px !important;
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
    padding-top: 0px !important;
    padding-bottom: 0px  !important;
    padding-right: 0px  !important
}

.ReactTable .-pagination {
    padding: 0px  !important
}

.opacity-less {
    opacity: 0.5
}

.select {
    height: 26px;
    background: $bwhite;
    &.select1 {
        width: 185px;
    }
    
    &.select-input,  &.select-input-no-width {
        padding-top: 0px;
        border-radius: 3px;
    }
    &.select-input {
        width: 225px;
    }
    &.select-table {
        width: 100%;
        height: 26px;
    }

    &.select-expense {
        width: 145px;
    }

    &.select-password-length {
        width: auto;
    }
}

small {
    font-size: 80%
}

.paright, .paright0 {
    right: 0px
}

.zpp {
    right: 10px;
    top: 5px;
    text-align: center;
    line-height: 22px;
}

.kps {
    font-weight: 400;
    right: 210px;
    top: 2px;
}

.kps0 {
    font-weight: 400;
    right: 0px;
    top: 2px;
}

.kategorije {
    margin-top: 10px;
    margin-right: 10px;
}

.groups {
    .group {
        height: 22px;
        .groupmembers, .groupnumber {
            font-weight: 600;
            display: inline-block;
        }
        .groupmembers {
            width: 30px;
            text-align: center;
        }
        .groupnumber {
            width: 22px;
            text-align: right;
        }
        .groupname, .groupcolor {
            height: 22px;
            border: 0px;
        }
        .groupname {
            width: 280px;
            padding-left: 5px;
        }
        .groupcolor {
            width: 72px;
            padding-left: 3px;
        }
    }
}

.paright0 {
    top: 0px
}

.paright2 {
    right: 20px
}

.coderegister {
    .beforeinput {
        width: 230px
    }
}

.code-register {
    width: calc(100% - 480px)
}

.legend0 {
    width: 60px !important
}

.legend1 {
    width: calc(20% - 31px) !important
}

.ml0 {
    margin-left: 0px !important
}

.input-date {
    width: 189px !important
}

.input-date-0 {
    width: 100px !important
}

.select-date-1, .select-date-2, .select-date-3 {
    margin-top: 4px;
    font-size: 14px;
}

.select-date-1, .select-date-2 {
    width: 50px
}

.select-date-3 {
    width: 70px
}


.editp {
    top: 0px;
    right: 0px;
    .width {
        width: 200px
    }
}
.Beforeinput {
    width: 230px
}


.data-navigation {
    right: 0px;
    top: 2px;
    button {
        line-height: 20px;
        border: 1px solid $color;
        background: $bwhite;
        color: $color;
        &:hover {
            color: $bwhite;
            background: $color;
        }
        &:disabled {
            background: $bwhite;
            color: $color;
        }
    }
    span {
        line-height: 20px;
        border: 1px solid $blue;
        background: $blue;
        color: $bwhite;
        padding: 1px 6px
    }
}

.ml5 {
    margin-left: 5px
}

.select {
    &.tall {
        height: 24px;
    }
}
.startdateleft, .startdateright, .enddateleft, .enddateright, .customerright, .manipulantright,
.startdateleft2, .startdateright2, .enddateleft2, .enddateright2, .customerright2, .manipulantright2 {
    top: -10px;
    font-size: 13px
}

.customerright {
    left: 197px
}

.startdateleft {
    left: 257px;
}

.startdateright {
    left: 357px;
}

.enddateleft {
    left: 418px;
}

.enddateright {
    left: 518px;
}

.customerright2 {
    left: 237px
}

.startdateleft2 {
    left: 247px;
}

.startdateright2 {
    left: 397px;
}

.enddateleft2 {
    left: 458px;
}

.enddateright2 {
    left: 558px;
}

.manipulantright {
    left: 702px;
}

.delete {
    color: $red
}

.nodelete {
    color: $green
}


.no-delete {
    color: $grey
}

.edit {
    color: $blue
}

.popup {
    left: 20px;
    right: 20px;
    bottom: 20px;
    top: 45px;
    z-index: 9998;
    border: 2px solid $darkGrey;
    // background: $veryLightGrey;
    background: $white;
    overflow: auto;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.popup-close {
    right: 60px;
    top: 45px;
    z-index: 9999;
    font-size: 20px !important;
    color: $red
}

.popup-close-scroll {
    left: calc(50% + 352px);
    top: 45px;
    z-index: 9999;
    font-size: 20px !important;
    color: $red
}

.popup-close-scroll-wide {
    right: 40px;
    top: 45px;
    z-index: 9999;
    font-size: 20px !important;
    color: $red
}

.redbg {
    background: $bred;
    color: $bwhite
}

.edited {
    background: #fdd
}

.greenbg {
    background: $bgreen;
    color: $bwhite
}
.print {
    border: 1px solid $color;
    border-radius: 3px;
    font-size: 14px;
    padding: 0px 4px 2px
}

.box-shadow {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
    0 1px 5px 0 rgba(0,0,0,0.12),
    0 3px 1px -2px rgba(0,0,0,0.2);
}
.Sheet {
    padding: 10px;
}

.react-datepicker-wrapper {
    margin-left: 10px;
    display: block;
    float: left;
    height: 32px;
    width: 130px !important;

    .react-datepicker__input-container {
        height: 32px;
        input {
            line-height: 20px;
            width: 130px !important;
            text-align: left;
            padding-left: 6px
        }
    }
}

.datepicker-short {
    .react-datepicker-wrapper {
        width: 110px !important;
        .react-datepicker__input-container {
            input {
                width: 110px !important;
            }
        }
    }
}

.datepicker-shorter {
    .react-datepicker-wrapper {
        width: 102px !important;
        .react-datepicker__input-container {
            input {
                width: 102px !important;
            }
        }
    }
}

.lh20 {
    line-height: 20px
}

.add-point {
    left: 0px;
    top: 0px;
    font-weight: 700;
    width: 24px;
    text-align: center;
    font-size: 20px
}

.new-document-navigation {
    button {
        height: 50px;
        width: 115px;
    }
    button:last-of-type {
        width: 70px;
    }
}

.attach {
    margin-top: -5px;
    font-size: 12px;
    &.attach-0 {
        margin-left: 10px;
        width: 170px;
        height: 24px;
        overflow: hidden;
        color: blue;
        &.selected {
            color: $red
        }
    }
    &.attach-1 {
        width: 55px;
        height: 24px;
        text-align: right;
    }
}

.attachment-name {
    font-weight: 600;
    width: 150px;
    margin-right: 5px;
    overflow: hidden;
    color: blue;
    cursor: pointer;
    &.selected {
        color: $red
    }
}

.attachment-size {
    font-weight: 600;
    font-size: 12px;
    width: 60px;
    text-align: right
}

.react-tabs__tab {
    padding: 4px 12px 
}

@media only screen and (max-width: 1023px) {
    .show-mobile {
        display: block
    }

    .hide-mobile {
        display: none
    }
}

@media only screen and (min-width: 1024px) {
    .show-mobile {
        display: none
    }

    .hide-mobile {
        display: block
    }
}

.react-datepicker-popper {
    z-index: 9999;
}

.license-plate {
    font-family: Helvetica;
    input {
        font-family: Helvetica;
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 0px;
    }
}

.rt-smallrow .license-plate-table {
    font-family: Helvetica;
    margin-top: 2px;
    margin-bottom: -2px
}

.exclamation {
    border-radius: 50%;
    background: $red;
    color: $bwhite;
    height: 16px;
    width: 16px;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    font-weight: 700;
    z-index: 9998;

    &.mileage-planned, &.vehicle {
        left: 299px;
        top: 9px
    }
    
    &.files-left {
        left: 5px;
        top: 10px;
        background: $borange;
    }
    &.vehicle {
        background: $borange;
    }
    &.vehicle-planned, &.vehicle-type-planned {
        left: 285px;
        top: 8px
    }
    
    &.departure-date-planned, &.arrival-date-planned {
        left: 203px;
        top: 7px;
    }

    &.time-planned {
        left: 269px;
        top: 33px;
    }
}

.notifications {
    > div {
        .triangle {
            top: 0px;
            right: 0px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 70px 70px 0;
            border-color: transparent black transparent transparent;            
        }
        .triangle-text {
            top: 9px;
            right: -3px;
            transform: rotate(45deg);
            font-size: 24px;
            color: $bwhite;
            font-weight: 700;
            width: 50px;
            text-align: center
        }

        border: 2px dashed $color;
        width: 350px;
        height: 230px;
        padding: 3px 6px 0;
        margin-bottom: 10px;

        &.empty-notification {
            height: 65px;
            border: 0px;
            padding: 3px 0 0;
        }

        &.dsI, &.dsN, &.dsS, &.dsU, &.dsV, &.dsC, &.dsF, &.ds, &.dsg {
            border-style: solid;
        }

        &.ds, &.dsg {
            height: 73px;
            text-align: center;
        }

        &.ds {
            border-color: #CB4335;
            span {
                height: 75px;
                width: 80%;
                display: block;
                color: #CB4335;
                inset: 27px 0 0 0;
                margin: auto;
                font-size: 18px;
                line-height: 22px;
            }
        }

        &.dsg {
            border-color: #20bf6b;
            button {
                inset: 0px 55px 0 0;
                margin: auto;
                width: 150px;
                line-height: 18px;
                height: 30px;
            }
        }

        &.dsC {
            border-color: #4A235A;
            .triangle {border-right-color: #4A235A}
        }

        &.dsV {
            border-color: #0B5345;
            .triangle {border-right-color: #0B5345}
        }
/*
        &.dsS {
            border-color: #1B4F72;
            .triangle {border-right-color: #1B4F72}
        }
*/
        &.dsU {
            border-color: #641E16;
            .triangle {border-right-color: #641E16}
        }
/*
        &.dsV {
            border-color: #641E16;
            .triangle {border-right-color: #641E16}
        }
*/
        &.dsF {
            border-color: #17202A;
            .triangle {border-right-color: #17202A}
        }
/*
        &.dsF {
            border-color: #4D5656;
            .triangle {border-right-color: #4D5656}
        }
*/
        &.ds {
            border-color: #CB4335;
            .triangle {border-right-color: #CB4335}
        }

        &.dsg {
            border-color: #20bf6b;
            .triangle {border-right-color: #20bf6b}
        }

        > div {
            line-height: 24px;
            strong, div {
                line-height: 24px;
                font-size: 14px;
            }
        }
    }
}

.document-status {
    left: 120px;
    top: 0px;
    width: 200px;
    text-align: right;

    &.inline span {
        width: auto;
        text-align: left;
        left: auto;
        top: auto;
        font-size: 13px;
        font-weight: 600
    }

    span {
    
        padding: 1px 6px;
        color: $bwhite;

        &.dsC {
            background: #4A235A;
        }

        &.dsV {
            background: #0B5345;
        }
/*
        &.dsS {
            background: #1B4F72;
        }
*/
        &.dsU {
            background: #641E16;
        }
/*
        &.dsV {
            background: #641E16;
        }
*/
        &.dsF {
            background: #17202A;
        }
/*        
        &.dsF {
            background: #4D5656;
        }
*/
    }
}

.ds__ {
    color: $color;
    background: $bwhite;
    border: 1px solid $color;
    font-weight: 600;
    width: 28px;
    height: 28px;
    line-height: 27px;
    border-radius: 50%;
    text-align: center;

    &.asterisk {
        padding-top: 3px;
        font-size: 24px
    }

    &:hover, &.selected {
        color: $bwhite;
        &.dsA {
            background: #1B4F72
        }

        &.dsU {
            background: #641E16;
        }

        &.dsV {
            background: #0B5345;
        }

        &.dsC {
            background: #4A235A;
        }
            
        &.dsF {
            background: #17202A;
        }
    }
}

.ds_ {
    color: $bwhite;
    font-weight: 600;
    width: 30px;
    border-radius: 50%;
    &.dsC {
        background: #4A235A;
    }

    &.dsV {
        background: #0B5345;
    }

    &.dsU {
        background: #641E16;
    }

    &.dsF {
        background: #17202A;
    }
}


.maxwidth {
    width: 320px
}

.bg-red {
    background: #fdd
}

.bg-green {
    background: #dfd
}

.bg-blue {
    background: #ddf
}

.flag {
    right: 2px;
    top: 2px;
    height: 26px;
    border: 1px solid $color;
    &.flag-centered {
        inset: 0;
        height: 20px;
        margin: auto
    }
}

img.shown {
    display: block;
    width: 316px;
    cursor: pointer;
}

div.shown {
    border: 2px solid $color;
    width: 320px;
    &:hover {
        border-color: $bgreen;
        opacity: 0.7
    }
    &.no-border {
        border-width: 0px;
    }
}

img.hidden {
    display: none
}

div.enlarge-image {
    width: 320px;
    padding-top: 0px;
    text-align: center;
    line-height: 20px;
}

a.enlarge-image {
    color: blue;
    line-height: inherit;
    font-size: 12px;
}

a.cancel-image {
    top: -2px;
    right: 0px;
    &:hover {
        color: $red
    }
}

div.show-image {
    width: 320px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
}

.large-image, .large-pdf {
    inset: 0;
    margin: 45px 20px 20px 20px;
    border: 2px solid $color;
    background: $bwhite;
    z-index: 10000;
    overflow: hidden;
    div {
        height: 100%;
        width: 100%;

        a {
            color: $red;
            top: 5px;
            right: 5px;
            font-size: 30px;
        }
    }

    overflow-y: scroll;
    .page-numbers {
        bottom: 35px;
        right: 40px;
        width: auto;
        height: auto;
    }

    .orientation {
        bottom: 35px;
        left: 30px;
        width: auto;
        height: auto;
    }

    .zoom {
        top: 50px;
        left: 30px;
        width: auto;
        height: auto;
    }

    .pdf-close {
        top: 50px;
        right: 40px;
    }

    .image-close {
        top: 50px;
        right: 40px;
    }
}

.large-image {
    div {
        img {
            inset: 0;
            margin: auto;
            display: block;
            max-width: 100%;
            max-height: 100%
        }
    }
}

.point-name {
    line-height: 24px;
    width: 197px;
}

.point-short {
    line-height: 24px;
    width: 76px;
    text-align: right;
    font-weight: 700;
}

.point-address {
    line-height: 24px;
    width: 297px;
}


.vehicle-name {
    line-height: 24px;
    width: 155px;
}

.vehicle-plate {
    line-height: 24px;
    font-family: Helvetica;
    width: 118px;
    text-align: right;
    font-size: 18px;
}

.select-vehicle-plate {
    font-family: Helvetica;
    width: auto !important;
    padding-top: 2px !important
}

.vehicle-active {
    width: 24px;
}

.react-pdf__Document .react-pdf__Page .react-pdf__Page__canvas {text-align: center; margin-left: auto; margin-right: auto; height: 100%}

.mobile-table {width: 320px}

.green {
    color: $green
}

.third {
    width: 33.333333%;
    padding-left: 50px;
    padding-right: 50px;
    &:first-of-type {padding-left: 0px;}
    &:last-of-type {padding-right: 0px;}
}

.half {
    width: 50%;
    padding-left: 20px;
    padding-right: 20px;
    &:first-of-type {padding-left: 0px;}
    &:last-of-type {padding-right: 0px;}
}

.third-narrow {
    width: 33.333333%;
    padding-left: 20px;
    padding-right: 20px;
    &:first-of-type {padding-left: 0px;}
    &:last-of-type {padding-right: 0px;}
}

.two-thirds-narrow {
    width: 66.666666%;
    padding-left: 20px;
    padding-right: 20px;
    &:first-of-type {padding-left: 0px;}
    &:last-of-type {padding-right: 0px;}
}

.half-narrow {
    width: 50%;
    padding-left: 20px;
    padding-right: 20px;
    &:first-of-type {padding-left: 0px;}
    &:last-of-type {padding-right: 0px;}
}

input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #367ebd;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
  }

#Sheet {
    position: absolute;
    left: -10130px;
    width: 1110px;
}

#Sheet0 {
    position: absolute;
    left: -1130px;
    width: 1110px;
}

#Sheet00 {
    margin-left: -805px;
    width: 785px;
}

.nohit0, .nohit1, .nohit9, .nohit99, .nohit2, .nohit3, .nohit10, .nohit11, .nohit12 {
    width: 100px;
    top: 2px
}

.nohit0 {
    left: 130px
}

.nohit1 {
    left: 66px
}

.nohit2 {
    left: 26px
}

.nohit3 {
    left: 33px
}

.nohit9 {
    left: 66px;
    top: 2px
}

.nohit99 {
    left: 86px;
    top: 2px
}

.nohit10 {
    left: 625px;
    top: 2px
}

.nohit11 {
    left: 215px;
    top: 2px
}

.nohit111 {
    left: 215px;
    top: 1px
}

.nohit12 {
    left: 495px;
    top: 2px
}

.reset-password {
    font-size: 13px;
}

.password-problem {
    margin-top: 20px;
    font-size: 13px;
    background: rgba(248, 248, 248, 0.8);
    height: 21px;
    line-height: 19px;
    border: 1px dashed $color;
}

.loader {
    height: 100px;
    width: 100px;
    position: fixed;
    margin: auto;
    inset: 0;
    z-index: 10000;
}

.version {
    right: 10px;
    font-size: 11px;
    font-weight: 400;
}

.changelog {
    padding-left: 20px;
    padding-right: 20px
}

.ml8 {
    margin-left: 8px
}

.vo {
    width: 380px;
    height: 50px;
    margin-top: 5px;
    background: $white;
    border: 1px solid $lightGrey;

    &.vo-mine {
        background: #dfd;
        border: 1px solid $bgreen
    }
    
    &.vo-foreign {
        background: #ddf;
        border: 1px solid $bblue;
    }

    .r1,.r2,.r3,.r4 {font-size: 14px}
    .r1 {top: -2px; left: 5px;}
    .r2 {top: 20px; left: 5px; span {font-size: 14px}}
    .r3 {top: -2px; right: 5px;}
    .r4 {top: 20px; right: 5px;}
}

.bb {
    &:hover {
        color: #fff !important;
        background: #60a3bc !important
    }
    &:disabled {
        background: #eee !important;
        color: #60a3bc !important
    }
}

#sheet0 {
    .average {
        line-height: 24px;
    }
}

.styled-table {
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 0.9em;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table th,
.styled-table td {
    padding: 3px 12px;
    text-align: left;
}

.styled-table.print th,
.styled-table.print td {
    padding: 1px 6px;
    text-align: left;
    line-height: 24px;
}


.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table thead tr {
    border-bottom: 2px solid #000;
}
.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #000;
}

.transparent-datepicker {color: transparent}

.Sheet0 {
    overflow: hidden;
    padding: 10px;
    .sheet0 {
        overflow: hidden;
    }
}

.ReactTable, .ReactTable .rt-thead .rt-th, .ReactTable .rt-tbody .rt-td {
    border-color: rgba(0,0,0,0.5) !important;
}

.ReactTable .rt-thead .rt-tr {
    height: 30px;
}

.ReactTable .rt-thead .rt-th {
    padding-top: 0px;
    padding-bottom: 0px;
}

.ReactTable .rt-noData {
    display: none
}

.ReactTable .-pagination .-pageJump input {
    height: 20px;
    border: 0px;
    width: 40px;
}

.ReactTable .-pagination .-btn {
    padding: 0px 0px;
}

h4 {
    font-size: 18px;
}

#ketoprint .input {
    margin-top: 3px;
    margin-bottom: 3px;
}

.popup-shown {
    position: fixed;
    inset: 36px 0 0 0;
    opacity: 0.5;
    background: $black;
    z-index: 1;
}
.popup {
    #subsistence-data {
        overflow-y: scroll;
        max-height: 258px
    }

    #expenses-data {
        overflow-y: scroll;
        max-height: 130px
    }
}

.free-vehicles {
    margin-bottom: 20px;
}

.free-vehicle {
    span {
        font-family: Helvetica, sans-serif;
        margin-left: 10px;
    }
}